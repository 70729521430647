import FormLabelGroup from '@appfolio/react-gears/lib/components/FormLabelGroup';
import Input from '@appfolio/react-gears/lib/components/Input';
import React from 'react';
import { TextFieldProps } from './TextField';

// TODO: This is an attempt to modify the onChange but it's not working
export interface UrlFormFieldProps extends Omit<TextFieldProps, 'onChange'> {
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const UrlFormField = ({
  label,
  required,
  hint,
  rowClassName,
  validate,
  initialValue,
  feedback,
  onChange,
  ...inputProps
}: UrlFormFieldProps) => (
  <FormLabelGroup
    stacked
    label={label}
    required={required}
    hint={hint}
    rowClassName={rowClassName}
    feedback={feedback}
  >
    <Input
      {...inputProps}
      onChange={event => onChange(event.target.value.replace(/\s/g, ''), event)}
    />
  </FormLabelGroup>
);

export default UrlFormField;
