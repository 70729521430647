import isBlank from '@im-frontend/utils/isBlank';

export function fincenIdValidator(fincenId: string) {
  const patterns = [/^\d{12}$/, /^\d{4}-\d{4}-\d{4}$/];
  const match = patterns.find(pattern => pattern.test(fincenId));

  if (isBlank(fincenId) || match) {
    return undefined;
  }
  return 'Please enter a valid FinCEN ID format: ####-####-#### or ############';
}
